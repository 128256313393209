.cnc-backbround{
    background-color: rgb(36, 37, 37) !important; 
}

.ant-spin-dot-item{
    background-color: #DADAB3 !important;
}
.content-logo-container{
    /* position: absolute; */
    bottom: 10px;
    /* width: 50%; */
    /* border: 3px solid #8AC007; */
}

.coming-soon-page{
    color: white;
    font-size: 30px;
    text-align: center;
    margin-top: 150px;
}